// Media query breakpoints ----------------------------------------------------
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// Media queries --------------------------------------------------------------
$xs: "only screen and (min-width: #{$screen-xs})";
$sm: "only screen and (min-width: #{$screen-sm})";
$md: "only screen and (min-width: #{$screen-md})";
$lg: "only screen and (min-width: #{$screen-lg})";

h1 a.site-h1 {
  text-decoration: none !important; }

article img {
  max-width: 100%;
  height: auto;

  display: block;
  margin-left: auto;
  margin-right: auto;

  border-radius: 0.4rem!important; }

article img + em {
  display: block;
  text-align: center;
  font-size: 0.75em;
  font-style: normal; }

article figure img + em {
  display: block;
  text-align: center;
  font-size: 0.75em;
  font-style: normal; }

// used for image alignment in image_aligned.html include
.image-left {
  float: left!important;
  margin-right: 1em;
  margin-bottom: 1em; }

// used for image alignment in image_aligned.html include
.image-right {
  float: right!important;
  margin-left: 1em;
  margin-bottom: 1em; }


.card-img-top {
    width: 100%;
    height: 50vw;
    object-fit: cover;

    @media #{$xs} {
      height: 25vw; } }

blockquote {
  padding-left: 1em;
  padding-right: 1em;
  font-style: italic;
  border-left: 0.4em solid #ccc; }

.navbar {
  padding-left: 0rem;
  padding-right: 0rem;

  a.nav-item.nav-link {
    color: #5a5a5a; } }

.post-description {
  a.badge {
    vertical-align: text-bottom; } }
